





























































import { getPlatformSelectOptions } from '@/common/platforms';
import { Select, Option } from 'element-ui';
import { CollectionConditionExposureType } from '../model/condition';
import Vue from 'vue';
export default Vue.extend({
  name: 'CollectionImageInfo',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      condition: JSON.parse(JSON.stringify(this.item)),
      platformsOptions: getPlatformSelectOptions(),
      exposureTypeOptions: [
        {
          value: CollectionConditionExposureType.MAIN,
          text: '메인',
        },
        {
          value: CollectionConditionExposureType.DETAIL,
          text: '세부 사항',
        },
      ],
    };
  },
  methods: {
    updateInput() {
      this.$emit('change', this.condition);
    },
  },
});
