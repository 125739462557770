




































































































































import Vue, { VueConstructor } from 'vue';
import { ApolloError } from 'apollo-client';
import { Select, Option } from 'element-ui';
import { Table, TableColumn } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ClientPlatform } from '@frientrip/domain';
import { apolloClient } from '@/apolloClient';
// import ShortcutInfoForm from '../components/ShortcutInfoForm.vue';
import { SLOT_QUERY } from '../queries/query';
import {
  CollectionSlot,
  CollectionSlotCreateParam,
  CollectionSlotUpdateParam,
  CollectionType,
} from '../model/slot';
import CollectionImageInfo from '../components/CollectionImageInfo.vue';
// import { CollectionCondition } from '../model/condition';
import { CollectionService } from '../service/CollectionService';
import { CollectionConditionExposureType } from '../model/condition';

const collectionService = new CollectionService(apolloClient);

export default // eslint-disable-next-line @typescript-eslint/no-explicit-any
(Vue as VueConstructor<Vue & { $refs: { [key: string]: any } }>).extend({
  name: 'CollectionSlotForm',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
    CollectionImageInfo,
    // draggable,
    // ShortcutInfoForm,
  },
  data() {
    return {
      isAnyChaning: true,
      slotId: this.$route.params.slotId,
      form: {
        id: '',
        description: '',
        maximumCountOfCollection: '',
        collectionType: this.$attrs.CollectionType as CollectionType,
        conditions: [
          {
            id: '',
            maxImageSize: '',
            imageWidth: '',
            imageHeight: '',
            platform: [],
            exposureType: '' as CollectionConditionExposureType,
          },
        ] as {
          id: string;
          maxImageSize: string | number;
          imageWidth: string | number | undefined;
          imageHeight: string | number | undefined;
          platform: ClientPlatform[];
          exposureType: CollectionConditionExposureType;
          createdAt?: number;
        }[],
      },
      loading: 0,
    };
  },
  computed: {},
  methods: {
    initData(): void {
      this.form.id = '';
      this.form.description = '';
      this.form.maximumCountOfCollection = '';
      this.form.conditions = [];
    },
    imageInfoEvent(
      e: {
        id: string;
        maxImageSize: number;
        imageWidth: number;
        imageHeight: number;
        platform: ClientPlatform[];
        exposureType: CollectionConditionExposureType;
      },
      idx: number
    ) {
      this.form.conditions[idx].maxImageSize = e.maxImageSize;
      this.form.conditions[idx].imageWidth = e.imageWidth;
      this.form.conditions[idx].imageHeight = e.imageHeight;
      this.form.conditions[idx].platform = e.platform;
      this.form.conditions[idx].exposureType = e.exposureType;
    },
    async saveCollectionSlot(): Promise<void> {
      this.$modal.show(
        {
          title: '정말 저장 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            if (!this.isCreatePage()) {
              const param = this.getUpdateParam();
              await collectionService.updateCollectionSlot(param);
              this.$apollo.queries.form.refetch();
            }
            if (this.isCreatePage()) {
              const param = this.getCreateParam();
              await collectionService.createCollectionSlot(param);
              this.$router.push({
                name: this.isShowingImageInfo()
                  ? 'CollectionThemeSlotUpdate'
                  : 'CollectionProductSlotUpdate',
                params: { slotId: param.id },
              });
              this.initData();
              this.slotId = param.id;
              await this.$apollo.queries.form.refetch();
            }
            this.showSuccessMessage('저장 완료 되었습니다.');
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    getCreateParam(): CollectionSlotCreateParam {
      const {
        id,
        description,
        maximumCountOfCollection,
        collectionType,
        conditions,
      } = this.form;

      return {
        id,
        description,
        maximumCountOfCollection: Number(maximumCountOfCollection),
        collectionType,
        conditions: this.isShowingImageInfo()
          ? conditions.map(
              ({
                maxImageSize,
                imageWidth,
                imageHeight,
                platform,
                exposureType,
              }) => ({
                maxImageSize: Number(maxImageSize),
                imageWidth: imageWidth?.toString().length
                  ? Number(imageWidth)
                  : undefined,
                imageHeight: imageHeight?.toString().length
                  ? Number(imageHeight)
                  : undefined,
                platform,
                exposureType,
              })
            )
          : [],
      };
    },
    getUpdateParam(): CollectionSlotUpdateParam {
      const { id, description, maximumCountOfCollection, conditions } =
        this.form;

      return {
        id,
        description,
        maximumCountOfCollection: Number(maximumCountOfCollection),
        conditions: this.isShowingImageInfo()
          ? conditions.map(
              ({
                maxImageSize,
                imageWidth,
                imageHeight,
                platform,
                exposureType,
              }) => ({
                maxImageSize: Number(maxImageSize),
                imageWidth: imageWidth?.toString().length
                  ? Number(imageWidth)
                  : undefined,
                imageHeight: imageHeight?.toString().length
                  ? Number(imageHeight)
                  : undefined,
                platform,
                exposureType,
              })
            )
          : [],
      };
    },
    async deleteSlot(): Promise<void> {
      this.$modal.show(
        {
          title: '정말 삭제하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            await collectionService.deleteCollectionSlot(this.form.id);
            this.showSuccessMessage('삭제 되었습니다.');
            this.$router.push({
              name: this.isShowingImageInfo()
                ? 'CollectionThemeSlotList'
                : 'CollectionProductSlotList',
            });
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    showErrorMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '실패',
        message: message.replace('GraphQL error:', ''),
        type: 'warning',
      });
    },
    showSuccessMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '성공',
        message: message,
        type: 'success',
      });
    },
    isCreatePage(): boolean {
      return !this.slotId || this.slotId.length === 0;
    },
    isShowingImageInfo(): boolean {
      return this.$attrs.CollectionType === CollectionType.THEME;
    },
    deleteCondition(index: number): void {
      this.form.conditions.splice(index, 1);
    },
    createCondition(): void {
      this.form.conditions.push({
        id: '',
        maxImageSize: '',
        imageWidth: '',
        imageHeight: '',
        platform: [],
        exposureType: '' as CollectionConditionExposureType,
        createdAt: new Date().getTime(),
      });
    },
  },

  apollo: {
    form: {
      query: SLOT_QUERY,
      variables(): { id: string } {
        return {
          id: this.slotId,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { slot: CollectionSlot };
      }): Omit<CollectionSlot, 'collections' | 'countOfVisibleCollections'> => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.slot));
      },
      skip(): boolean {
        return this.isCreatePage();
      },
    },
  },
});
